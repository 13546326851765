import React, { useState } from "react";

function QuizGroupsOptions({
  userData,
  options,
  setCurrentOptionsGroup,
  setUserTypeForm,
  isActiveVotingGroups,
  setIsActiveQuestionsGroups,
  setIsActiveVotingGroups,
  userLang,
}) {
  const [isActive, setisActive] = useState();

  const updateCurrentGroup = (groupNumber) => {
    setCurrentOptionsGroup(groupNumber);
    setIsActiveQuestionsGroups();
    setIsActiveVotingGroups(true);
    setisActive(groupNumber);
  };
  return (
    <div>
      {options?.map((option, key) => {
        let groupQuestionsNumber = Math.floor(key / 4 + 1);
        let questionNumber = key + 1;

        if (key % 4 === 0 && questionNumber < 20) {
          return (
            <div
              onClick={() => {
                updateCurrentGroup(key / 4 + 1);
                setUserTypeForm("options");
                window.scrollTo(0, 0);
              }}
              className={
                groupQuestionsNumber === isActive && isActiveVotingGroups
                  ? "questions questions-selected text-body-2 color-grey-bg"
                  : "questions text-body-2 color-grey-bg"
              }
              key={key}
            >
              <div>
                {groupQuestionsNumber < 10 ? 0 : null}
                {groupQuestionsNumber}.{" "}
                {userLang === "es" ? "Grupo de preguntas" : "Questions group"}
              </div>
              {userData && userData?.options ? (
                userData?.options[
                  groupQuestionsNumber < 10
                    ? "G0" + groupQuestionsNumber
                    : "G" + groupQuestionsNumber
                ]?.done === true ? (
                  <div>
                    <img alt="check" src="/assets/img/icon-done.png"></img>
                  </div>
                ) : userData?.options[
                    groupQuestionsNumber < 10
                      ? "G0" + groupQuestionsNumber
                      : "G" + groupQuestionsNumber
                  ] ? (
                  <span>
                    {
                      Object.keys(
                        userData?.options[
                          groupQuestionsNumber < 10
                            ? "G0" + groupQuestionsNumber
                            : "G" + groupQuestionsNumber
                        ]
                      ).length
                    }{" "}
                    <span>{userLang === "es" ? "de" : "of"} 4</span>
                  </span>
                ) : (
                  <div className="empty-div"></div>
                )
              ) : (
                <div className="empty-div"></div>
              )}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

export default QuizGroupsOptions;
