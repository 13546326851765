import React, { useEffect, useState } from "react";
import { getUserDataDB, saveResponsesDB } from "../lib/db";
import { changeColorOptionResponseDiv } from "../lib/utils";

import "../styles/quiz-form-options.scss";

function QuizFormOptions({
  userInfo,
  currentOptionsGroup,
  userData,
  questionsData,
  setUserData,
  userLang,
}) {
  const [questionsSelected, setQuestionsSelected] = useState([]);
  const [responses, setResponses] = useState([]);
  const [group, setGroup] = useState([]);

  useEffect(() => {
    let questionsDataArray = Object.entries(questionsData.options);
    let questionTempSelected = [];
    for (
      let i = currentOptionsGroup * 4 - 4;
      i < currentOptionsGroup * 4;
      i++
    ) {
      questionTempSelected.push(questionsDataArray[i]);
    }
    setQuestionsSelected(questionTempSelected);
    if (currentOptionsGroup < 10) {
      setGroup("0" + currentOptionsGroup);
    }
  }, [questionsData, currentOptionsGroup]);
  const saveResponses = async () => {
    let type = "options";
    await saveResponsesDB(
      userInfo.id,
      userInfo.companyId,
      currentOptionsGroup,
      responses,
      type
    );
    setUserData(await getUserDataDB(userInfo.id, userInfo.companyId));
    setResponses();
    window.scrollTo(0, 0);
  };

  const saveResponse = (questionId, value) => {
    let updatedResponses = [];
    if (responses) {
      updatedResponses = [...responses];
    }

    if (updatedResponses) {
      let updatedResponsesFiltered = updatedResponses.filter(function (el) {
        return el.questionId !== questionId;
      });
      updatedResponses = [
        ...updatedResponsesFiltered,
        { questionId: questionId, value: value, timestamp: Date.now() },
      ];
    }
    setResponses(updatedResponses);
  };

  return (
    <div>
      <p className="quiz-form-title">
        {userLang === "es" ? "ESCENARIOS" : "SCENARIOS"}
      </p>
      <h3 className="quiz-form-subtitle">
        {userLang === "es"
          ? "Indique el escenario más predominante en su organización"
          : "Indicate the most predominant scenario in your organization"}
      </h3>
      {questionsSelected?.map((question, key) => {
        return (
          <div key={question[0]}>
            <div className="quiz-form-question-div">
              <h2 className="quiz-form-question-number">0{key + 1}.</h2>
              <div className="quiz-form-question-img-options-div">
                {questionsData.questions[question[1].option1].image ? (
                  <div className="quiz-form-question-img-options-div-item">
                    <img
                      className="quiz-form-question-img"
                      src={questionsData.questions[question[1].option1].image}
                      alt="Option_Image"
                    />
                    <p className="quiz-form-question-img-letter">a.</p>
                  </div>
                ) : null}
                {questionsData.questions[question[1].option2].image ? (
                  <div className="quiz-form-question-img-options-div-item">
                    <img
                      className="quiz-form-question-img"
                      src={questionsData.questions[question[1].option2].image}
                      alt="Option_Image"
                    />
                    <p className="quiz-form-question-img-letter">b.</p>
                  </div>
                ) : null}
              </div>
              <div className="quiz-form-options-radio-input-div">
                <div
                  id={`div-op-1-${question[0]}`}
                  className={
                    group &&
                    question[0] &&
                    userData &&
                    userData.options &&
                    userData.options[`G${group}`] &&
                    userData.options[`G${group}`][question[0]] &&
                    userData.options[`G${group}`][question[0]].value === 1
                      ? "quiz-form-options-radio-input-item quiz-form-options-radio-input-item-pressed"
                      : "quiz-form-options-radio-input-item"
                  }
                >
                  <input
                    onClick={() => {
                      saveResponse(question[0], 1);
                      changeColorOptionResponseDiv(question[0], 1);
                    }}
                    type="radio"
                    id={`btn-op1${question[0]}`}
                    name={`btn-op${question[0]}`}
                    value="1"
                    defaultChecked={
                      group &&
                      question[0] &&
                      userData &&
                      userData.options &&
                      userData.options[`G${group}`] &&
                      userData.options[`G${group}`][question[0]] &&
                      userData.options[`G${group}`][question[0]].value === 1
                    }
                  ></input>
                  <label
                    htmlFor={`btn-op1${question[0]}`}
                    className="text-body-1 quiz-form-options-radio-input-label"
                  >
                    {questionsData.questions[question[1].option1].text}
                  </label>
                </div>
                <div
                  id={`div-op-2-${question[0]}`}
                  className={
                    group &&
                    question[0] &&
                    userData &&
                    userData.options &&
                    userData.options[`G${group}`] &&
                    userData.options[`G${group}`][question[0]] &&
                    userData.options[`G${group}`][question[0]].value === 0
                      ? "quiz-form-options-radio-input-item quiz-form-options-radio-input-item-pressed"
                      : "quiz-form-options-radio-input-item"
                  }
                >
                  <input
                    onClick={() => {
                      saveResponse(question[0], 0);
                      changeColorOptionResponseDiv(question[0], 0);
                    }}
                    type="radio"
                    id={`btn-op2${question[0]}`}
                    name={`btn-op${question[0]}`}
                    value="0"
                    defaultChecked={
                      group &&
                      question[0] &&
                      userData &&
                      userData.options &&
                      userData.options[`G${group}`] &&
                      userData.options[`G${group}`][question[0]] &&
                      userData.options[`G${group}`][question[0]].value === 0
                    }
                  ></input>
                  <label
                    htmlFor={`btn-op2${question[0]}`}
                    className="text-body-1 quiz-form-options-radio-input-label"
                  >
                    {questionsData.questions[question[1].option2].text}
                  </label>
                </div>
                <div
                  id={`div-op-na-${question[0]}`}
                  className={
                    group &&
                    question[0] &&
                    userData &&
                    userData.options &&
                    userData.options[`G${group}`] &&
                    userData.options[`G${group}`][question[0]] &&
                    userData.options[`G${group}`][question[0]].value === 2
                      ? "quiz-form-options-radio-input-item quiz-form-options-radio-input-item-pressed"
                      : "quiz-form-options-radio-input-item"
                  }
                >
                  <input
                    onClick={() => {
                      saveResponse(question[0], 2);
                      changeColorOptionResponseDiv(question[0], 2);
                    }}
                    type="radio"
                    id={`btn-op3${question[0]}`}
                    name={`btn-op${question[0]}`}
                    value="2"
                    defaultChecked={
                      group &&
                      question[0] &&
                      userData &&
                      userData.options &&
                      userData.options[`G${group}`] &&
                      userData.options[`G${group}`][question[0]] &&
                      userData.options[`G${group}`][question[0]].value === 2
                    }
                  ></input>
                  <label
                    htmlFor={`btn-op3${question[0]}`}
                    className="text-body-1 quiz-form-options-radio-input-label"
                  >
                    {userLang === "es" ? "NS/NC" : "NA"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="quiz-form-question-btn-submit-div">
        <button
          onClick={() => {
            saveResponses();
          }}
          className="btn-accent quiz-form-question-btn-submit"
        >
          {userLang === "es"
            ? "GUARDAR GRUPO DE PREGUNTAS"
            : "SAVE QUESTION GROUP"}
        </button>
      </div>
    </div>
  );
}

export default QuizFormOptions;
