// En Home.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/login.scss";
import "../styles/home.scss";
import Navbar from "./navbar";
import ModalNewQuiz from "./modal-new-quiz";
import ModalEditProfile from "./modal-edit-profile";
import { getUserInfoDB } from "../lib/db";

function Home() {
  const [user, setUser] = useState();
  const [questionsData, setQuestionData] = useState();
  const [userInfo, setUserInfo] = useState();
  const [showModalStartQuiz, setShowModalStartQuiz] = useState();
  const [userLang] = useState("es");

  const [userGender, setUserGender] = useState("-");
  const [userAge, setUserAge] = useState("-");

  const [showModalDepartament, setShowModalDepartament] = useState(false); // Inicializado como falso
  let navigate = useNavigate();

  useEffect(() => {
    let userSession = JSON.parse(sessionStorage.getItem("user_IRT"));
    setUser(userSession);
    let questionsDataSession = JSON.parse(
      sessionStorage.getItem("questions_data_IRT")
    );
    setQuestionData(questionsDataSession.questionsData);
    sessionStorage.removeItem("id_IRT");
    sessionStorage.removeItem("email_IRT");
    sessionStorage.removeItem("isLoading_IRT");
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.state.user) {
      setUser(location.state.user);
      sessionStorage.removeItem("id_IRT");
      sessionStorage.removeItem("email_IRT");
      sessionStorage.removeItem("isLoading_IRT");
      getUserDepartament(
        location.state.user.userId,
      );
    }
    if (location.state.questionsData) {
      setQuestionData(location.state.questionsData);
    }
  }, [location]);


  useEffect(() => {
    if (user && user.userCompanyInfo && questionsData) {
      setUserInfo({
        id: user.userId,
        email: user.userEmail,
        name: user.userName,
        companyName: user.userCompanyInfo.name,
        companyId: user.userCompanyInfo.id,
      });
    }
  }, [user, questionsData]);

  const goToQuiz = (userFormState, userInfo) => {
    
    navigate("/quiz", {
      state: {
        user: {
          userInfo: userInfo,
          userAge: userAge,
          userGender: userGender,
          userStateForm: userFormState,
        },
        questionsData: questionsData,
      },
    });
  };

  const getUserDepartament = async (userId) => {
    const data = await getUserInfoDB(userId);
    if (Object.values(data).length > 0) {
      setUserAge(data.age);
      setUserGender(data.gender);
    }
  };

  return (
    <div className="home-div">
      <Navbar
        userLang={userLang}
        userName={user?.userName || "Usuario"}
        setShowModalDepartament={setShowModalDepartament}
        isQuizPage={true}
      />
      {showModalStartQuiz && (
        <ModalNewQuiz
          goToQuiz={goToQuiz}
          userInfo={userInfo}
          setShowModalStartQuiz={setShowModalStartQuiz}
          userLang={userLang}
        />
      )}
      {showModalDepartament && (
        <ModalEditProfile
          userInfo={userInfo}
          setShowModalDepartament={setShowModalDepartament}
          userGenderProp={userGender}
          userAgeProp={userAge}
          setUserGenderProp={setUserGender}
          setUserAgeProp={setUserAge}
          userLang={userLang}
        />
      )}

      <div className="form-div">
        <img src="/assets/img/home.png" alt="home" />
        <div className="form-buttons">
          <button
            onClick={() => {
              setShowModalStartQuiz(true);
            }}
            className="btn-primary-m btn-options-quiz"
          >
            {userLang === "es" ? "COMENZAR CUESTIONARIO " : "START QUIZ"}
          </button>
          <button
            onClick={() => {
              goToQuiz("continue", userInfo);
            }}
            className="btn-accent btn-options-quiz"
          >
            {userLang === "es" ? "CONTINUAR CUESTIONARIO " : "CONTINUE QUIZ"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
