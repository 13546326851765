import firebaseConfig from "./firebase";
import "firebase/compat/database";
import { initializeApp } from "@firebase/app";

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { child, get, getDatabase, ref, set, update } from "firebase/database";
import {
  arrayUnion,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref as refStorage,
  getDownloadURL,
} from "firebase/storage";

initializeApp(firebaseConfig);
const db = getDatabase();
const dbFirestore = getFirestore();
const auth = getAuth();
const storage = getStorage();

export const singIn = async (email) => {
  const password = "Incliva,042024";
  let companyName = process.env.REACT_APP_COMPANY_ID;
  return createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed in
      const user = userCredential.user.uid;
      await createUserInFirestoreDB(user, email, companyName);
      return user;
    })
    .catch(async (error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      return null;
    });
};

export const login = async (email) => {
  const password = "Incliva,042024";
  return await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};

export const addUserToCompanyDB = async (userId) => {
  let companyName = process.env.REACT_APP_COMPANY_ID;
  const companyRef = doc(dbFirestore, `companies/${companyName}`);
  //get company data
  const company = await getDoc(companyRef);
  //get the number of users
  const totalUsers = company.data().totalUsers;
  await updateDoc(companyRef, {
    users: arrayUnion(userId),
    //add 1 to the number of users
    totalUsers: totalUsers + 1,
  });
  console.log("user added to the company");
};

export const registerDB = async (email, password) => {
  let companyName = process.env.REACT_APP_COMPANY_ID;
  return createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed in
      const user = userCredential.user.uid;
      await createUserInFirestoreDB(user, email, companyName);
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      // ..
    });
};

export const createUserInFirestoreDB = async (userId, email, companyName) => {
  return await setDoc(doc(dbFirestore, "users", userId), {
    name: email,
    id: userId,
    company: companyName,
    gender: "-",
    age: "-",
  });
};

export const getUserDataDB = async (userId, companyId) => {
  const dbRef = ref(getDatabase());
  return await get(child(dbRef, "usersData/" + userId + "/" + companyId))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
    });

  // let data;
  // const userDataRef = ref(db, "usersData/" + userId);
  // onValue(userDataRef, (snapshot) => {
  //   data = snapshot.val();
  //   return data;
  // });
  // if (data) {
  //   return data;
  // } else {
  //   return {};
  // }

  // let userData;
  // onValue(
  //   ref(db, "/usersData/" + userId),
  //   (snapshot) => {
  //     userData = snapshot.val();
  //   },
  //   {
  //     onlyOnce: false,
  //   }
  // );
  // if (userData) {
  //   return userData;
  // } else {
  //   return {};
  // }
};

export const getUserInfoDB = async (userId) => {
  const collectionRef = doc(dbFirestore, `users/`, userId);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return { name: "", company: "" };
  }
};

export const getUserDepartamentDB = async (userId, userCompany) => {
  const dbRef = ref(getDatabase());
  return await get(child(dbRef, "usersData/" + userId + "/" + userCompany))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const updateUserProfileDB = async (
  userInfo,
  userGender,
  userAge

) => {
  await update(ref(db, `usersData/${userInfo.id}/${userInfo.companyId}`), {
    gender: userGender,
    age: userAge,
  });

  const userRef = doc(dbFirestore, `users/${userInfo.id}`);
  await updateDoc(userRef, {
    gender: userGender,
    age: userAge,
  });
};

export const getUserCompanyInfoDB = async (userCompany) => {
  const collectionRef = doc(dbFirestore, `companies/${userCompany}`);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return { id: "", name: "" };
  }
};

export const getQuestionsDataDB = async (companyId) => {
  //get construcia data
  companyId = process.env.REACT_APP_COMPANY_ID
  const dbRef = ref(getDatabase());
  return await get(child(dbRef, "questionsData/" + companyId))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const saveResponsesDB = async (
  userId,
  companyId,
  questionsGroup,
  responses,
  type
) => {
  if (responses) {
    if (questionsGroup < 10) {
      questionsGroup = "0" + questionsGroup;
    }
    responses.map(async (response) => {
      await update(
        ref(
          db,
          `usersData/${userId}/${companyId}/${type}/G${questionsGroup}/${response.questionId}`
        ),
        {
          timestamp: response.timestamp,
          value: response.value,
          done: true,
        }
      );
    });

    const dbRef = ref(getDatabase());
    const numResponses = await get(
      child(
        dbRef,
        `usersData/${userId}/${companyId}/${type}/G${questionsGroup}/`
      )
    )
      .then(async (snapshot) => {
        if (snapshot.exists()) {
          return await snapshot.val();
        } else {
          return {};
        }
      })
      .catch((error) => {
        console.error(error);
      });
    if (Object.keys(numResponses).length >= 4) {
      await update(
        ref(db, `usersData/${userId}/${companyId}/${type}/G${questionsGroup}/`),
        {
          done: true,
        }
      );
    }
    console.log("Responses saved!");
  }
};

export const deleteUserResponsesDB = async (userId, companyId) => {
  await set(ref(db, `usersData/${userId}/${companyId}`), {});
};

export const getImgUrl = async (img) => {
  return await getDownloadURL(refStorage(storage, img))
    .then((url) => {
      return url;
    })
    .catch((error) => {
      console.log(error);
    });
};
