import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import { useForm } from "react-hook-form";
import {
  addUserToCompanyDB,
  getQuestionsDataDB,
  getUserCompanyInfoDB,
  getUserInfoDB,
  login,
  singIn,
} from "../lib/db";
import { useNavigate } from "react-router-dom";
import "../styles/login.scss";
import LoadingSpinner from "./loadingSpinner";
import { changeLoginButtonColor } from "../lib/utils";

import { getAuth, getRedirectResult } from "firebase/auth";

function Login() {
  let navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [user, setUser] = useState();
  const [userName, setUserName] = useState();
  const [userCompany, setUserCompany] = useState();
  const [userCompanyInfo, setUserCompanyInfo] = useState();
  const [questionsData, setQuestionsData] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("isLoading_IRT")) {
      setIsLoading(true);
    }
    if (sessionStorage.getItem("id_IRT")) {
      setIsLoading(true);
      setUserInState();
    }
  }, []);

  useEffect(() => {
    if (user) {
      getUserInfo(user.uid);
    }
  }, [user, userCompany]); // eslint-disable-line

  useEffect(() => {
    if (userCompany) {
      getUserCompanyInfo(userCompany);
      getQuestionsData(userCompany);
    }
  }, [userCompany]); // eslint-disable-line

  useEffect(() => {
    if (user && userCompanyInfo && questionsData) {
      goToHome(userName, userCompany, userCompanyInfo, questionsData);
    }
  }, [userName, userCompanyInfo, questionsData]); // eslint-disable-line

  const setUserInState = () => {
    setUser({ uid: sessionStorage.getItem("id_IRT") });
    setEmail(sessionStorage.getItem("email_IRT"));
  };

  const onSubmit = async () => {
    resetState();
    setIsLoading(true);
    let newUser = await singIn(email);
    let userLogged;
    if (newUser) {
      changeLoginButtonColor();
      userLogged = await login(email);
      await addUserToCompanyDB(newUser);
      console.log(userLogged);
      setUser(userLogged);
      // navigate("/home");
    } else {
      //user already exists
      userLogged = await login(email);
      console.log(userLogged);
      setUser(userLogged);
      // changeLoginButtonColorWhite();
      // setError(true);
      // setIsLoading(false);
    }
    setIsLoading(false);
  };

  const resetState = () => {
    setUser();
    setError(false);
  };

  const getUserInfo = async (userId) => {
    const data = await getUserInfoDB(userId);
    setUserName(data.name);
    setUserCompany(data.company);
  };

  const getUserCompanyInfo = async (userCompany) => {
    const data = await getUserCompanyInfoDB(userCompany);
    setUserCompanyInfo(data);
  };

  const getQuestionsData = async (userCompany) => {
    const data = await getQuestionsDataDB(userCompany);
    setQuestionsData(data);
  };

  const goToHome = (userName, userCompany, userCompanyInfo, questionsData) => {
    sessionStorage.setItem(
      "user_IRT",
      JSON.stringify({
        userId: user.uid,
        userEmail: email,
        userName: userName,
        userCompany: userCompany,
        userCompanyInfo: userCompanyInfo,
      })
    );
    sessionStorage.setItem(
      "questions_data_IRT",
      JSON.stringify({
        questionsData,
      })
    );
    navigate("/home", {
      state: {
        user: {
          userId: user.uid,
          userEmail: email,
          userName: userName,
          userCompany: userCompany,
          userCompanyInfo: userCompanyInfo,
        },
        questionsData: questionsData,
      },
    });
  };

  const auth = getAuth();
  getRedirectResult(auth)
    .then(async (result) => {
      if (result) {
        sessionStorage.setItem("id_IRT", result.user.uid);
        sessionStorage.setItem("email_IRT", result.user.email);
        window.location.reload(false);
      }
    })
    .catch((error) => {
      sessionStorage.removeItem("isLoading_IRT");
      console.log(error);
    });

  return (
    <div className="login-div">
      <Navbar login={true} />
      <div className="form-div">
        <div>
          <h2 className="form-title">LOG IN</h2>
          <hr className="form-line"></hr>
          <div className="form-inputs-div">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <p className="text-overline form-label">Email</p>
              <div>
                <input
                  {...register("email")}
                  id="email"
                  className="text-body-1 form-inputs"
                  type="text"
                  value={email}
                  placeholder="Your Email"
                  onChange={(event) => {
                    resetState();
                    setEmail(event.target.value);
                  }}
                  onFocus={() => changeLoginButtonColor()}
                ></input>
                {user ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-done.png"
                    alt="icon"
                  ></img>
                ) : error ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-error.png"
                    alt="icon"
                  ></img>
                ) : null}
              </div>
              {user ? (
                <hr className="form-hr-success"></hr>
              ) : error ? (
                <hr className="form-hr-error"></hr>
              ) : (
                <hr className="form-hr"></hr>
              )}

              <div className="form-submit-div">
                {isLoading ? (
                  <div>
                    {" "}
                    <div>
                      <LoadingSpinner />
                    </div>
                  </div>
                ) : null}

                {!isLoading && (
                  <button
                    id="btn-submit"
                    type="submit"
                    className="btn-accent form-submit-btn"
                  >
                    Entrar
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Login;
