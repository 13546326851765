import React, { useState } from "react";
import Navbar from "./navbar";
import { useForm } from "react-hook-form";
import { registerDB } from "../lib/db";
import { useNavigate } from "react-router-dom";
import "../styles/login.scss";
import LoadingSpinner from "./loadingSpinner";
import {
  changeLoginButtonColor,
  changeLoginButtonColorWhite,
} from "../lib/utils";

import { getAuth, getRedirectResult } from "firebase/auth";

function Register() {
  let navigate = useNavigate();
  const { register, required, handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    resetState();
    setIsLoading(true);
    const userRegistred = await registerDB(email, password);
    if (userRegistred) {
      navigate("/");
    } else {
      changeLoginButtonColorWhite();
      setError(true);
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setUser();
    setError(false);
  };

  const auth = getAuth();
  getRedirectResult(auth)
    .then(async (result) => {
      if (result) {
        sessionStorage.setItem("id_IRT", result.user.uid);
        sessionStorage.setItem("email_IRT", result.user.email);
        window.location.reload(false);
      }
    })
    .catch((error) => {
      sessionStorage.removeItem("isLoading_IRT");
      console.log(error);
    });

  return (
    <div className="login-div">
      <Navbar login={true} />
      <div className="form-div">
        <div>
          <h2 className="form-title">NUEVO USUARIO</h2>
          <hr className="form-line"></hr>
          <div className="form-inputs-div">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <p className="text-overline form-label">Email</p>
              <div>
                <input
                  {...register("email")}
                  id="email"
                  className="text-body-1 form-inputs"
                  type="text"
                  value={email}
                  placeholder="Your Email"
                  onChange={(event) => {
                    resetState();
                    setEmail(event.target.value);
                  }}
                  onFocus={() => changeLoginButtonColor()}
                ></input>
                {user ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-done.png"
                    alt="icon"
                  ></img>
                ) : error ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-error.png"
                    alt="icon"
                  ></img>
                ) : null}
              </div>
              {user ? (
                <hr className="form-hr-success"></hr>
              ) : error ? (
                <hr className="form-hr-error"></hr>
              ) : (
                <hr className="form-hr"></hr>
              )}
              <p className="text-overline form-label">Password</p>
              <div>
                <input
                  {...register("password", { required })}
                  id="password"
                  className="text-body-1 form-inputs"
                  type="password"
                  value={password}
                  autoComplete="off"
                  placeholder="Your Password"
                  onChange={(event) => {
                    resetState();
                    setPassword(event.target.value);
                  }}
                  onFocus={() => changeLoginButtonColorWhite()}
                ></input>
                {user ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-done.png"
                    alt="icon"
                  ></img>
                ) : error ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-error.png"
                    alt="icon"
                  ></img>
                ) : null}
              </div>
              {user ? (
                <hr className="form-hr-success"></hr>
              ) : error ? (
                <hr className="form-hr-error"></hr>
              ) : (
                <hr className="form-hr"></hr>
              )}
              <div className="form-submit-div">
                {isLoading ? (
                  <div>
                    {" "}
                    <div>
                      <LoadingSpinner />
                    </div>
                  </div>
                ) : null}
                {!isLoading && (
                  <button
                    onClick={() => navigate("/")}
                    type="button"
                    className="btn-accent form-submit-btn"
                  >
                    Login
                  </button>
                )}
                {!isLoading && (
                  <button
                    id="btn-submit"
                    type="submit"
                    className="btn-accent form-submit-btn"
                  >
                    Registrarme
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Register;
