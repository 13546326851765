import React, { useEffect, useState } from "react";
import { updateUserProfileDB } from "../lib/db";
import "../styles/modal-select-departament.scss";
import LoadingSpinner from "./loadingSpinner";

const ModalEditProfile = ({
  userInfo,
  setShowModalDepartament,
  userGenderProp,
  userAgeProp,
  setUserGenderProp,
  setUserAgeProp,
  userLang,
}) => {
  const [userGender, setUserGender] = useState(userGenderProp);
  const [userAge, setUserAge] = useState(userAgeProp);

  const [isLoading, setIsLoading] = useState(false); // false aqui y no en home

  useEffect(() => {
    setUserGender(userGenderProp);
  }, []);



  const getUserGender = (event) => {
    setUserGender(event.target.value);
  };

  const getUserAge = (event) => {
    setUserAge(event.target.value);
  };

  const updateUserProfile = async () => {
    if (userGender && userAge) {
      setIsLoading(true);
      await updateUserProfileDB(
        userInfo,
        userGender,
        userAge
      );
      if (setUserGenderProp) {
        setUserGenderProp(userGender);
      }
      if (setUserAgeProp) {
        setUserAgeProp(userAge);
      }

      setShowModalDepartament(false);
      setIsLoading(false);
    }
  };

  return (
    <div className="modal">
      <div className="modal-select-main">
        <div className="modal-select-div">
          <select
            value={userGender}
            onChange={getUserGender}
            className="modal-select"
            title={
              userLang === "es" ? "Seleccione Género *" : "Select Gender *"
            }
          >
            <option value="-" disabled>
              {userLang === "es" ? "Seleccione Género *" : "Select Gender *"}
            </option>
            <option value="MALE">
              {userLang === "es" ? "MASCULINO" : "MALE"}
            </option>
            <option value="FEMALE">
              {userLang === "es" ? "FEMENINO" : "FEMALE"}
            </option>
            <option value="UNDEFINED">
              {userLang === "es" ? "SIN DEFINIR" : "UNDEFINED"}
            </option>
          </select>

          <select
            value={userAge}
            onChange={getUserAge}
            className="modal-select"
            title={userLang === "es" ? "Seleccione Edad *" : "Select Age *"}
          >
            <option disabled value="-">
              {userLang === "es" ? "Seleccione Edad *" : "Select Age *"}
            </option>
            <option value="FROM 20 TO 30">
              {userLang === "en" ? "FROM 20 TO 30" : "DE 20 A 30"}
            </option>
            <option value="FROM 31 TO 40">
              {userLang === "en" ? "FROM 31 TO 40" : "DE 31 A 40"}
            </option>
            <option value="FROM 41 TO 50">
              {userLang === "en" ? "FROM 41 TO 50" : "DE 41 A 50"}
            </option>
            <option value="FROM 51 TO 60">
              {userLang === "en" ? "FROM 51 TO 60" : "DE 50 A 61"}
            </option>
            <option value="MORE THAN 61">
              {userLang === "en" ? "MORE THAN 61" : "MÁS DE 61"}
            </option>
          </select>

          <p style={{textAlign:"left", fontSize:"12px", width:"90%"}}>*Campos obligatorios</p>
          <div className="modal-select-btns-div">
            {!isLoading && (
              <div className="modal-profile-buttons-div">
                <button
                  disabled={
                    !userGender ||
                    userGender === "-" ||
                    !userAge ||
                    userAge === "-" 
                  }
                  style={{
                    cursor:
                      !userGender ||
                      userGender === "-" ||
                      !userAge ||
                      userAge === "-"
                        ? "default"
                        : "pointer",
                  }}
                  onClick={() => {
                    updateUserProfile();
                    setShowModalDepartament(false);
                  }}
                  className="btn-primary-m btn-options-quiz"
                >
                  {userLang === "en" ? "SAVE" : "GUARDAR"}
                </button>
                <button
                  onClick={() => setShowModalDepartament(false)}
            
                  className="btn-accent btn-options-quiz"
                >
                  {userLang === "en" ? "CANCEL" : "CANCELAR"}
                </button>
              </div>
            )}
            {isLoading && (
              <div className="modal-profile-buttons-div">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditProfile;
