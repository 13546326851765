import React from "react";

import "../styles/quiz-form.scss";
import QuizFormOptions from "./quiz-form-options";
import QuizFormQuestions from "./quiz-form-questions";

function QuizForm({
  userInfo,
  userStateForm,
  currentQuestionsGroup,
  currentOptionsGroup,
  userTypeForm,
  questionsData,
  userData,
  setUserData,
  userLang,
}) {
  return (
    <div>
      <div className="hr-div">
        <hr />
      </div>
      <div className="quiz-form-div">
        {userTypeForm === "questions" ? (
          <QuizFormQuestions
            userInfo={userInfo}
            userData={userData}
            userStateForm={userStateForm}
            questionsData={questionsData}
            currentQuestionsGroup={currentQuestionsGroup}
            currentOptionsGroup={currentOptionsGroup}
            userTypeForm={userTypeForm}
            setUserData={setUserData}
            userLang={userLang}
          />
        ) : userTypeForm === "options" ? (
          <QuizFormOptions
            userInfo={userInfo}
            userData={userData}
            userStateForm={userStateForm}
            questionsData={questionsData}
            currentQuestionsGroup={currentQuestionsGroup}
            currentOptionsGroup={currentOptionsGroup}
            userTypeForm={userTypeForm}
            setUserData={setUserData}
            userLang={userLang}
          />
        ) : (
          <h4>
            {userLang === "es"
              ? "Seleccione un grupo de preguntas para"
              : "Select a questions group to"}{" "}
            {userStateForm === "new" && userLang !== "es" && "start"}
            {userStateForm === "new" && userLang === "es" && "empezar"}
            {userStateForm !== "new" && userLang !== "es" && "continue"}
            {userStateForm !== "new" && userLang === "es" && "continuar"}
            {userLang === "es" ? " el cuestionario" : " the quiz"}
          </h4>
        )}
      </div>
    </div>
  );
}

export default QuizForm;
