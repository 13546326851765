import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/navbar.scss";
function Navbar({
  userName,
  bg,
  setShowModalDepartament,
  userLang,
  login,
  isQuizPage, // Hide logos in quiz page
}) {
  const navigate = useNavigate();
  return (
    <div className={bg ? `navbar-div${bg}` : "navbar-div"}>
      <div style={{ width: "50%" }}>
        <img
          onClick={() => navigate("/")}
          className="img-logo"
          src="/assets/img/logo-navbar.png"
          alt="logo"
        />
      </div>
      {!isQuizPage && ( // Condición para no renderizar los logos en la página del cuestionario
        <div style={{ width: "50%", display: "flex", justifyContent: "end" }}>
          <img
            style={{ cursor: "default" }}
            className="img-ldig"
            src="/assets/img/ldig.png"
            alt="logo"
          />
        </div>
      )}

      <div>
        {userName ? (
          <div className="user-info-div">
            <div className="user-info">
              <p className="text-body-2">
                {userLang === "es" ? "Hola!" : "Hi!"} {userName}
              </p>
              <button
                onClick={() => setShowModalDepartament(true)} // Esta función abre el modal de edición de perfil
                className="btn-edit-profile"
              >
                {userLang === "es" ? "Editar Perfil" : "Change Profile"}
              </button>
              <Link style={{ fontFamily: "Lato" }} to={"/login"}>
                {userLang === "es" ? "Cerrar sesión" : "Log out"}
              </Link>
            </div>
            <img src="/assets/img/icon-login.png" alt="login" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Navbar;
